<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <v-btn
      v-if="user.role != 'Manager'"
      :to="{ name: 'certificatesCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
      >{{ $t("MENU.ADD") }} {{ $t("BUTTON.NEW") }}
      {{ $t("COMPONENTS.CERTIFICATE") }}</v-btn
    >

    <br /><br />

    <v-btn
      @click="bomRegister()"
      class="btn btn-danger btn-elevate kt-login__btn-primary text-white"
    >
      <span
        v-if="downloadBomLoader"
        class="fa fa-spinner fa-spin mr-1 align-middle"
      ></span>
      Изтегли Регистър за получаване и регистриране на Вътрешен партиден номер
      на BOM</v-btn
    >

    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <!-- <v-checkbox v-model="tableOptions.showSearch"
                      label="Търси по колона"
                      class="shrink"></v-checkbox>  -->
        </v-col>
        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.year"
            :items="getYears()"
            label="Търси по година"
            chips
            attach
          ></v-select>
        </v-col>
        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col
            :key="column.name"
            :cols="column.cols"
            v-if="column.hide != true"
          >
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>
        <v-col cols="1" @click="changeOrder('signed_by_qualified')">
          Подписан
          <span v-if="'signed_by_qualified' === headers.order">{{
            orderIcon
          }}</span>
        </v-col>
        <v-col cols="4"> Операции </v-col>
      </v-row>

      <div v-show="!isLoadingCertificates">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="col.hide != true"
            >
              <template v-if="col.name === 'status.name' && !item[col.name]">
                Карантина
              </template>
              <template v-else>
                {{ item[col.name] }}
              </template>
            </v-col>
          </template>
          <v-col cols="1" class="t-col">
            {{ item.signedByQualified === 1 ? "Да" : "Не" }}
          </v-col>
          <v-col
            cols="4"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <EditButton
              v-if="
                ![
                  'awaiting_reanalysis_permission',
                  'awaiting_reanalysis',
                  'reanalyzed'
                ].includes(item['status.slug']) &&
                  ((item.signedByQualified !== 1 && user.role != 'Manager') ||
                    user.role == 'Admin' ||
                    user.role == 'Qualified' ||
                    (user.role == 'Quality Manager' &&
                      !item.signedByQualified)) &&
                  !(item.signedByQualified == 1 && item.statusId == 3)
              "
              :id="item.id"
              action-name="certificatesCreateUpdate"
            />
            <SignButton
              v-if="
                (user.role == 'Admin' || user.role == 'Qualified') &&
                  item.signedByQualified != 1 &&
                  item.statusId >= 2
              "
              :sign="sign"
              :id="item.id"
            />
            <v-tooltip
              top
              v-if="item.statusId === '' && user.role != 'Manager'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#3dbdad"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    openGenerateCertificateDialog(item.id, item.materialId)
                  "
                >
                  <v-icon> fas fa-file-invoice </v-icon>
                </v-btn>
              </template>
              <span>Генерирай сертификат</span>
            </v-tooltip>
            <DownloadPdfButton
              v-if="
                [
                  'osvoboden',
                  'awaiting_reanalysis_permission',
                  'awaiting_reanalysis',
                  'reanalyzed'
                ].includes(item['status.slug'])
              "
              :id="item.id"
              file-type="certificate"
              text="Изтегли сертификат"
              list-action="certificatesList"
            />
            <DownloadPdfButton
              :id="item.id"
              file-type="sampling-protocol"
              text="Изтегли протокол"
              list-action="certificatesList"
            />
            <PrintStickersButton
              :items="[
                {
                  statusId: item.statusId,
                  bom: item['material.type.name'],
                  nameLp: item['material.name'],
                  batch: item.batch,
                  date: item.date,
                  name: item['stickerName']
                }
              ]"
            />
            <RelatedRMButtons
              title="Свързани МК"
              @open-modal="openRelatedRM(item.id)"
            />
            <DeleteButton
              v-if="
                item.statusId == 2 &&
                  (user.role === 'Admin' || user.role === 'Qualified')
              "
              :id="item.id"
              :delete-action="deleteAction"
              :fetch-data="fetchData"
              @response-errors="error_messages = $event"
            />
            <LogLink :id="item.id" type="certificate" class-name="row" />
            <CreateCertificateReanalysisRequestButton
              v-if="
                item.signedByQualified &&
                  ['osvoboden', 'reanalyzed'].includes(item['status.slug']) &&
                  item.residualQuantity &&
                  item.residualQuantity > 0 &&
                  ['Admin', 'Qualified', 'Quality Manager'].includes(user.role)
              "
              :certificateId="item.id"
              @success="fetchData"
            />
            <ApproveCertificateReanalysisRequestButton
              v-if="
                item['status.slug'] === 'awaiting_reanalysis_permission' &&
                  ['Admin', 'Qualified', 'Manager'].includes(user.role)
              "
              :certificateId="item.id"
              @success="fetchData"
            />
            <DeclineCertificateReanalysisRequestButton
              v-if="
                item['status.slug'] === 'awaiting_reanalysis_permission' &&
                  ['Admin', 'Qualified', 'Manager'].includes(user.role)
              "
              :certificateId="item.id"
              @success="fetchData"
            />
            <ProcessCertificateReanalysisRequestButton
              v-if="
                item['status.slug'] === 'awaiting_reanalysis' &&
                  ['Admin', 'Qualified', 'Quality Manager'].includes(user.role)
              "
              :certificateId="item.id"
              @success="fetchData"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingCertificates"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>
    <v-dialog v-model="generateCertificateDialog.display" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Генериране на сертификат
        </v-card-title>

        <v-card-text>
          <div
            v-if="
              typeof error_messages === 'object' &&
                Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, i) in error_messages">
                <span v-bind:key="i">
                  <li v-for="(message, ii) in error" v-bind:key="ii">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Статус:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  :options="certificateStatuses"
                  label="name"
                  v-model="generateCertificateDialog.statusId"
                  :value="generateCertificateDialog.statusId"
                  :reduce="certificateStatuses => certificateStatuses.id"
                  @input="changeCertificateStatus"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-group">
              <label for="">
                <span> {{ generateCertificateDialog.conclusionLabel }}</span>
              </label>
              <div class="field-wrap">
                <textarea
                  v-model="generateCertificateDialog.conclusion"
                  class="form-control"
                ></textarea
                ><br />
              </div>
            </div>
          </v-col>
          <template v-if="generateCertificateDialog.statusId === 2">
            <template v-for="detail in generateCertificateDialog.results">
              <v-row v-bind:key="detail.id" class="detail-labels">
                <v-col>
                  {{ detail.propertyName }}
                </v-col>
                <v-col>
                  {{ detail.valueName }}
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="detail.answer"
                    color="red"
                    hide-details
                  ></v-checkbox>
                  Отговаря
                </v-col>
              </v-row>
            </template>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              generateCertificate(generateCertificateDialog.certificateId)
            "
          >
            Генерирай
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="relatedRMDialogDisplay" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Свързани маршрутни карти
        </v-card-title>

        <v-card-text>
          <div class="container">
            <template v-if="routeMaps.length > 0">
              <div class="row">
                <div class="col-sm col-lg-1">id</div>
                <div class="col-sm col-lg-4">Име</div>
                <div class="col-sm">Партида</div>
                <div class="col-sm">Мениджър</div>
                <div class="col-sm">Дата</div>
              </div>
              <div class="row" v-for="routeMap in routeMaps" :key="routeMap.id">
                <div class="col-sm col-lg-1">
                  {{ routeMap.id }}
                </div>
                <div class="col-sm col-lg-4">
                  {{ routeMap.product.name }}
                </div>
                <div class="col-sm">
                  {{ routeMap.cost_standards[0].typeable.batch }}
                </div>
                <div class="col-sm">
                  {{ routeMap.product_manager.name }}
                </div>
                <div class="col-sm">
                  {{ routeMap.start_date }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                <h2>Няма намерени резултати</h2>
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import { GET_FILE } from "@/store/files.module";
import { APP_URL } from "@/common/config";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_ROUTEMAPS_BY_CERTIFICATE } from "@/store/routemaps.module";
import {
  BOM_REGISTER,
  GET_CERTIFICATES,
  GENERATE_CERTIFICATE,
  SIGN_CERTIFICATE,
  DELETE_CERTIFICATE
} from "@/store/certificates.module";

import { mapGetters } from "vuex";
import { GET_DATA } from "@/store/formselect.module";
import { GET_ALL_MATERIALS } from "@/store/materials.module";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import LogLink from "@/components/Log/LogLink";
import SignButton from "@/components/ActionButtons/SignButton";
import EditButton from "@/components/ActionButtons/EditButton";
import DeleteButton from "@/components/ActionButtons/DeleteButton";
import DownloadPdfButton from "@/components/ActionButtons/DownloadPdfButton";
import PrintStickersButton from "@/components/ActionButtons/PrintStickersButton";
import CreateCertificateReanalysisRequestButton from "@/components/ActionButtons/CreateCertificateReanalysisRequestButton";
import ApproveCertificateReanalysisRequestButton from "@/components/ActionButtons/ApproveCertificateReanalysisRequestButton";
import DeclineCertificateReanalysisRequestButton from "@/components/ActionButtons/DeclineCertificateReanalysisRequestButton";
import ProcessCertificateReanalysisRequestButton from "@/components/ActionButtons/ProcessCertificateReanalysisRequestButton";
import RelatedRMButtons from "../../../components/ActionButtons/RelatedButtons";

export default {
  name: "ListCertificates",

  components: {
    RelatedRMButtons,
    SignButton,
    LogLink,
    Paginator,
    EditButton,
    DownloadPdfButton,
    PrintStickersButton,
    CreateCertificateReanalysisRequestButton,
    ApproveCertificateReanalysisRequestButton,
    DeclineCertificateReanalysisRequestButton,
    ProcessCertificateReanalysisRequestButton,
    DeleteButton
  },
  mixins: [ListMixin],
  data() {
    return {
      deleteAction: DELETE_CERTIFICATE,
      downloadBomLoader: false,
      relatedRMDialogDisplay: false,
      action: "certificates",
      items: [],
      routeMaps: [],
      error_messages: [],
      totalItems: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "batch",
            search: ""
          },
          {
            name: "signed_by_qualified",
            search: "",
            hide: true
          },
          {
            name: "status_id",
            search: "",
            hide: true
          },
          {
            name: "material_id",
            search: "",
            hide: true
          },
          {
            name: "material.type.name",
            search: ""
          },
          {
            name: "material.name",
            search: ""
          },
          {
            name: "user.name",
            search: ""
          },
          {
            name: "date"
          },
          {
            name: "status.name"
          },
          {
            name: "status.slug",
            hide: true
          },
          {
            name: "quantity",
            hide: true
          },
          {
            name: "residual_quantity",
            hide: true
          },
          {
            name: "sticker_name",
            hide: true
          }
        ],
        year: "",
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      },
      generateCertificateDialog: {
        display: false,
        certificateId: null,
        conclusion:
          "Опаковъчните материали отговарят на изискванията на утвърдената спецификация за окачествяване на опаковъчен материал на лекарствения продукт - СП БФ КК № /МОЛЯ ПОПЪЛНЕТЕ/",
        conclusionLabel: "Заключение (Сертификат)",
        statusId: null,
        results: []
      },
      certificateStatuses: [],
      materials: []
    };
  },
  computed: {
    ...mapGetters(["isLoadingCertificates"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  watch: {
    "headers.search": _.debounce(function(new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    }, 250),
    "headers.year": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      vm.fetchData();
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Сертификат за освобождаване на опаковъчни материали" }
    ]);

    vm.fetchData();
    vm.getStatuses();
  },
  methods: {
    getYears() {
      let startYear = 2021;
      let currentYear = new Date().getFullYear();
      let years = [];

      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      return years;
    },
    openRelatedRM: function(certificateId) {
      var vm = this;

      this.$store
        .dispatch(GET_ROUTEMAPS_BY_CERTIFICATE, { certificateId })
        .then(data => {
          vm.routeMaps = data.data;
          vm.relatedRMDialogDisplay = true;
        });
    },
    changeCertificateStatus: function() {
      let vm = this;

      if (vm.generateCertificateDialog.statusId === 3) {
        vm.generateCertificateDialog.conclusion = "";
        vm.generateCertificateDialog.conclusionLabel = "Причини";
      } else {
        vm.generateCertificateDialog.conclusionLabel =
          "Заключение (Сертификат)";
        vm.generateCertificateDialog.conclusion =
          "Опаковъчните материали отговарят на изискванията на утвърдената спецификация за окачествяване на опаковъчен материал на лекарствения продукт - СП БФ КК № /МОЛЯ ПОПЪЛНЕТЕ/";
      }
    },
    generateCertificate: function(id) {
      let vm = this;

      vm.error_messages = [];

      if (vm.validationFailed()) {
        return;
      }

      const payload = {
        id,
        payload: vm.generateCertificateDialog
      };

      this.$store
        .dispatch(GENERATE_CERTIFICATE, payload)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();

          vm.generateCertificateDialog.display = false;
        })
        .catch(response => {
          vm.error_messages = response.data.errors;

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    },
    openGenerateCertificateDialog: function(id, materialId) {
      let vm = this;
      vm.generateCertificateDialog.statusId = null;

      vm.generateCertificateDialog.certificateId = id;

      if (vm.materials.length === 0) {
        vm.getMaterials(materialId);
      } else {
        vm.filterResults(materialId);
      }

      vm.generateCertificateDialog.display = true;
    },
    filterResults: function(materialId) {
      let vm = this;

      vm.generateCertificateDialog.results = _.find(
        vm.materials,
        material => material.id === materialId
      ).details;

      vm.generateCertificateDialog.results = vm.generateCertificateDialog.results.map(
        result => ({
          ...result,
          answer: false
        })
      );
    },
    validationFailed: function() {
      const vm = this;

      let message = "";
      let hasError = false;

      if (vm.generateCertificateDialog.statusId === 2) {
        let hasFailedResult =
          vm.generateCertificateDialog.results.filter(result => {
            return result.answer !== true;
          }).length > 0;

        if (hasFailedResult) {
          message =
            'За издаване на сертификата и използване на материалите е необходимо статусът да бъде "Освободен" и да отговаря на всички изисквания';
          hasError = true;
        }
      } else if (vm.generateCertificateDialog.statusId < 2) {
        message = "Полето статус е задължително";
        hasError = true;
      } else if (vm.generateCertificateDialog.conclusion.length === 0) {
        let concusionLabel = vm.generateCertificateDialog.conclusionLabel;

        message = `Полето ${concusionLabel} е задължително`;
        hasError = true;
      }

      if (hasError) {
        vm.error_messages = {
          errors: {
            status: message
          }
        };

        return true;
      }

      return false;
    },
    sign: function(id) {
      let vm = this;
      var action = SIGN_CERTIFICATE;

      this.error_messages = [];

      vm.$store
        .dispatch(action, id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    },
    bomRegister: _.debounce(function() {
      let vm = this;
      vm.downloadBomLoader = true;

      vm.$store
        .dispatch(BOM_REGISTER, vm.headers.year)
        .then(data => {
          vm.downloadBomLoader = false;
          vm.getFile(data.data.id);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    }, 500),
    getMaterials: function(materialId) {
      let vm = this;

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_MATERIALS)
        .then(data => {
          vm.$nextTick(function() {
            vm.materials = data.data;

            vm.filterResults(materialId);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getStatuses: function() {
      let vm = this;

      let payload = {
        certificateStatuses: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];

      vm.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.certificateStatuses = data.data.certificateStatuses.filter(
              status => {
                return ["Освободен", "Блокиран"].includes(status.name);
              }
            );
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_CERTIFICATES, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "certificatesList" });
          }
        });
    },
    getFile: _.debounce(function(id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });

            vm.$router.push({ name: vm.listAction });
          }
        });
    }, 500),
    streamFile: function(code) {
      window.open(APP_URL + "file/" + code);
    }
  },
  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
