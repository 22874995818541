<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#3dbdad"
        v-bind="attrs"
        v-on="on"
        @click="sign(id)"
      >
        <v-icon>
          fas fa-file-signature
        </v-icon>
      </v-btn>
    </template>
    <span>Подпиши</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SignButton",
  props: ["sign", "id"]
};
</script>

<style scoped></style>
